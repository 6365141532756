import { trackPiwikAddToCart, trackPiwikRemoveFromCart } from './Piwik/PiwikEcommerce';
import { trackEventInPiwik, TrackEvent } from './Piwik/PiwikEvent';
import { trackGA4AddToCart, trackGA4RemoveFromCart } from './trackGA4Events';

export type GenericTrackData = TrackEvent & GATrackEvent;
export type iFixitTrackingData = Omit<GenericTrackData, 'eventName'> &
   Partial<Pick<GenericTrackData, 'eventName'>>;

export type GATrackEvent = {
   /**
    * Describes the type of events you want to track.
    * For example, Link Clicks, Videos, Outbound Links, and Form Events.
    */
   eventCategory: string;
   /**
    * The specific action that is taken.
    * For example, with a Video category, you might have a Play, Pause and Complete action.
    */
   eventAction: string;
   /**
    * Usually the title of the element that is being interacted with, to aid with analysis.
    * For example, it could be the name of a Video that was played or the specific
    * form that is being submitted.
    */
   eventName: string;
};

/**
 * @param trackData trackData.eventName will default to the page path if not provided
 */
const trackInPiwikAndGA = (trackData: iFixitTrackingData) => {
   const dataWithEventName = {
      ...trackData,
      eventName: trackData.eventName || `${window.location.origin}${window.location.pathname}`,
   };

   trackEventInPiwik(dataWithEventName);
   if (window.gtag) {
      window.gtag('event', dataWithEventName.eventAction, {
         event_category: dataWithEventName.eventCategory,
         event_label: dataWithEventName.eventName,
         event_value: dataWithEventName.eventValue,
      });
   }
};

const trackLinkInPiwikAndGA = (link: HTMLElement, trackData: iFixitTrackingData) => {
   link.addEventListener('click', () => {
      trackInPiwikAndGA(trackData);
   });
};

type AnalyticsItem = {
   item_id: string;
   item_name: string;
   quantity: number;
   price: number;
};

type AnalyticsItemsEvent = {
   items: AnalyticsItem[];
   value?: number;
   currency?: string;
};

const trackAnalyticsAddToCart = (event: AnalyticsItemsEvent) => {
   trackPiwikAddToCart(event.items);
   trackGA4AddToCart(event);
};

const trackAnalyticsRemoveFromCart = (event: AnalyticsItemsEvent) => {
   trackPiwikRemoveFromCart(event.items);
   trackGA4RemoveFromCart(event);
};

export default trackInPiwikAndGA;
export {
   trackLinkInPiwikAndGA,
   AnalyticsItem,
   AnalyticsItemsEvent,
   trackAnalyticsAddToCart,
   trackAnalyticsRemoveFromCart,
};
